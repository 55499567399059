import React from 'react'
import PropTypes from 'prop-types'
import { InfoCircleOutlined } from '@ant-design/icons'

const DocumentUploadCard = ({
  title,
  description,
  documentId,
  isUploaded,
  isLoading,
  fileUrl,
  onInfoClick,
  onFileChange,
  onPreview,
  fileError,
  isOptional = false
}) => {
  return (
    <div className='w-full'>
      <div className='flex gap-4 items-center mb-4'>
        <h2 className='text-base tracking-tight font-medium'>
          {title} {isOptional && <span className='text-red-500'>(Optional)</span>}
        </h2>
        <InfoCircleOutlined
          style={{ fontSize: '16px', color: '#08c', marginTop: '5px' }}
          onClick={() => onInfoClick(title, description)}
        />
      </div>
      <div className='bg-transparent flex flex-col items-center border border-gray-300 shadow-sm p-4 rounded-md'>
        {isUploaded && !isLoading ? (
          <>
            <p className='text-sm px-6 mx-auto mb-2 text-center'>Uploaded successfully</p>
            <p className='mb-2'>
              <a href='#' onClick={() => onPreview(fileUrl)} className='text-blue-500'>
                View
              </a>
            </p>
            <label
              htmlFor={`fileInput${documentId}`}
              className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
            >
              Change file
            </label>
          </>
        ) : !isLoading ? (
          <>
            <p className='mb-2 text-center'>Drag and drop your document</p>
            <p className='text-gray-500 text-sm mt-2'>OR</p>
            <label
              htmlFor={`fileInput${documentId}`}
              className='cursor-pointer bg-white rounded-sm py-2 px-4 text-sm text-gray-800'
            >
              Browse file
            </label>
          </>
        ) : (
          <p className='mb-2 text-center'>Uploading...</p>
        )}
        <input
          type='file'
          name={title}
          id={`fileInput${documentId}`}
          className='hidden'
          accept='image/*, .pdf, .doc, .docx, .ppt, .pptx, .txt, .xls, .xlsx'
          onChange={event => onFileChange(event, documentId)}
        />
      </div>
      {fileError && <small className='text-red-500'>{fileError}</small>}
    </div>
  )
}

DocumentUploadCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  isUploaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  fileUrl: PropTypes.string,
  onInfoClick: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  fileError: PropTypes.string,
  isOptional: PropTypes.bool
}

export default DocumentUploadCard
