/* eslint-disable indent */
import { Formik, Form, Field } from 'formik'
import { Modal, notification, Spin, Table, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { InputField } from '../../../../sharedComponents/input-field'
import { useEffect, useState } from 'react'
import { adminServices } from '../../../../services'
import { CopyOutlined } from '@ant-design/icons'
import { NumericFormat } from 'react-number-format'
import { cleanCurrencyValue } from '../../../../utils/helpers'

const UpdateBankStatementModal = ({
  showModal,
  setShowModal,
  onSubmit,
  uploading,
  bankStatement,
  approvedLoanId,
  loanId,
  fetchBankStatements
}) => {
  const [formRefreshKey, setFormRefreshKey] = useState(Math.random())
  const [initialValues, setInitialValues] = useState({
    bank_name: bankStatement?.bank_name || '',
    account_number: bankStatement?.account_number || '',
    opening_balance: bankStatement?.balance || '',
    total_credit: bankStatement?.total_credit || '',
    total_debit: bankStatement?.total_debit || '',
    total_turnover: bankStatement?.total_turnover || '',
    average_turnover: bankStatement?.average_turnover || '',
    document_url: bankStatement?.file_url || '',
    start_date: bankStatement?.start_date || '',
    end_date: bankStatement?.end_date || '',
    file: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isBankStatementModalVisible, setIsBankStatementModalVisible] = useState(false)
  const [bankStatementData, setBankStatementData] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  const handleViewStatement = fileUrl => {
    if (fileUrl) {
      window.open(fileUrl, '_blank')
    }
  }

  const handleModalClose = () => {
    setFormRefreshKey(Math.random())
    setShowModal(false)
  }

  const handleDocumentSubmission = async values => {
    if (
      !values.bank_name ||
      !values.opening_balance ||
      !values.total_credit ||
      !values.total_debit ||
      !values.account_number
    ) {
      notification.error({
        message: 'Error',
        description: 'All fields required'
      })
      return
    }
    try {
      const { data } = await adminServices.updateRiskAnalysisBankStatement(bankStatement?.id, {
        bank_name: values.bank_name,
        opening_balance: cleanCurrencyValue(values.opening_balance),
        total_credit: cleanCurrencyValue(values.total_credit),
        total_debit: cleanCurrencyValue(values.total_debit),
        acc_no: values.account_number,
        document_url: bankStatement?.file_url
      })
      if (data?.data?.risk_analysis_id) {
        notification.success({
          message: 'Success',
          description: 'Bank statement updated successfully'
        })
        handleModalClose()
        setIsFetching(true)
        await fetchBankStatements()
        setIsFetching(false)
      } else {
        notification.error({
          message: 'Error',
          description: data?.message || 'Failed to add bank statement'
        })
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Failed to update bank statement'
      })
    }
  }

  const handleCopy = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notification.success({
          message: 'Copied',
          description: 'Copied to clipboard successfully'
        })
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Failed to copy to clipboard'
        })
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      if (showModal && approvedLoanId && bankStatement?.id) {
        setIsLoading(true)
        try {
          const { data: cashFlowAnalysisData } = await adminServices.processRiskAnalysisDocument(
            approvedLoanId,
            bankStatement?.id,
            {}
          )

          // Get values from either updated_document or bank_statement, preferring non-null values
          const documentData = {
            bank_name:
              cashFlowAnalysisData.updated_document?.bank_name ||
              cashFlowAnalysisData.bank_statement?.bank_name ||
              cashFlowAnalysisData.extracted_data?.bank_name ||
              '',
            account_number:
              cashFlowAnalysisData.updated_document?.account_number ||
              cashFlowAnalysisData.bank_statement?.acc_no ||
              cashFlowAnalysisData.extracted_data?.acc_no ||
              '',
            opening_balance:
              cashFlowAnalysisData.updated_document?.balance ||
              cashFlowAnalysisData.bank_statement?.opening_balance ||
              cashFlowAnalysisData.extracted_data?.opening_balance ||
              '',
            total_credit:
              cashFlowAnalysisData.updated_document?.total_credit ||
              cashFlowAnalysisData.bank_statement?.total_credit ||
              cashFlowAnalysisData.extracted_data?.total_credit ||
              '',
            total_debit:
              cashFlowAnalysisData.updated_document?.total_debit ||
              cashFlowAnalysisData.bank_statement?.total_debit ||
              cashFlowAnalysisData.extracted_data?.total_debit ||
              '',
            url:
              cashFlowAnalysisData.updated_document?.file_url ||
              cashFlowAnalysisData.bank_statement?.url ||
              cashFlowAnalysisData.extracted_data?.url ||
              '',
            statement_start_date:
              cashFlowAnalysisData.extracted_data?.statement_start_date ||
              cashFlowAnalysisData.bank_statement?.statement_start_date ||
              '',
            statement_end_date:
              cashFlowAnalysisData.extracted_data?.statement_end_date ||
              cashFlowAnalysisData.bank_statement?.statement_end_date ||
              ''
          }

          if (cashFlowAnalysisData.dti_results && cashFlowAnalysisData.extracted_data) {
            // Use the structure with dti_results and extracted_data
            setInitialValues({
              bank_name: documentData.bank_name,
              account_number: documentData.account_number,
              opening_balance: documentData.opening_balance,
              total_credit: cashFlowAnalysisData.dti_results.overall_metrics.total_credit || '',
              total_debit: cashFlowAnalysisData.dti_results.overall_metrics.total_debit || '',
              total_turnover: cashFlowAnalysisData.dti_results.overall_metrics.total_turnover || '',
              average_turnover:
                cashFlowAnalysisData.dti_results.statement_metrics[0]?.average_turnover || '',
              document_url: documentData.url,
              statement_start_date: documentData.statement_start_date,
              statement_end_date: documentData.statement_end_date,
              file: null
            })
            setBankStatementData(cashFlowAnalysisData.extracted_data)
          } else {
            // Use the existing structure
            setInitialValues({
              bank_name: documentData.bank_name,
              account_number: documentData.account_number,
              opening_balance: documentData.opening_balance,
              total_credit: documentData.total_credit,
              total_debit: documentData.total_debit,
              total_turnover: cashFlowAnalysisData?.total_turnover || '',
              average_turnover: '',
              document_url: documentData.url,
              statement_start_date: documentData.statement_start_date,
              statement_end_date: documentData.statement_end_date,
              file: null
            })
            setBankStatementData({
              bank_name: documentData.bank_name,
              acc_no: documentData.account_number,
              opening_balance: documentData.opening_balance,
              total_credit: documentData.total_credit,
              total_debit: documentData.total_debit,
              url: documentData.url,
              statement_start_date: documentData.statement_start_date,
              statement_end_date: documentData.statement_end_date
            })
          }
        } catch (error) {
          console.error('Error fetching cash flow analysis data:', error)
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()
  }, [showModal, approvedLoanId, bankStatement?.id])

  const columns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text, record) => (
        <div className='flex items-center'>
          <span>{text}</span>
          {(record.field === 'Acc No' || record.field === 'Document Id') && (
            <Tooltip title='Copy'>
              <CopyOutlined
                onClick={() => handleCopy(text)}
                className='ml-2 cursor-pointer text-blue-500 hover:text-blue-700'
              />
            </Tooltip>
          )}
        </div>
      )
    }
  ]

  const formatFieldName = fieldName => {
    // Convert snake_case or camelCase to a more readable format
    return fieldName
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
  }

  const dataSource = bankStatementData
    ? Object.entries(bankStatementData)
        .filter(([key]) => key !== 'url')
        .map(([key, value]) => ({
          key,
          field: formatFieldName(key),
          value: value.toString()
        }))
    : []

  // Add NumberInput component
  const NumberInput = ({ field, form, label, ...props }) => {
    return (
      <div className='px-2 mb-6 flex flex-col'>
        <label className='text-gray-600 font-medium'>{label}:</label>
        <NumericFormat
          {...field}
          {...props}
          thousandSeparator=','
          prefix='₦'
          allowLeadingZeros={false}
          thousandsGroupStyle='thousand'
          onValueChange={values => {
            form.setFieldValue(field.name, values.value)
          }}
          customInput={InputField}
          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
        />
      </div>
    )
  }

  // Add PropTypes for NumberInput
  NumberInput.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired
  }

  return (
    <Modal
      open={showModal}
      onCancel={handleModalClose}
      footer={null}
      destroyOnClose
      centered
      width={800}
    >
      <div className='header_modal bg-primary w-full flex justify-between px-3 py-5'>
        <h2 className='text-white font-bold tracking-tighter text-2xl'>Cash Flow Analysis</h2>
      </div>
      {isLoading || isFetching ? (
        <div className='flex justify-center items-center h-full mt-5'>
          <Spin size='large' />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleDocumentSubmission}
          enableReinitialize
          key={formRefreshKey}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className='w-full my-8 px-4'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div className='px-2 mb-6 flex flex-col'>
                    <label className='text-gray-600 font-medium'>Bank Name:</label>
                    <Field name='bank_name'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          placeholder='Enter bank name'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className='text-gray-600 font-medium'>Account Number:</label>
                    <Field name='account_number'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          placeholder='Enter account number'
                          className='w-2/3 p-2 border rounded focus:outline-none focus:border-blue-500'
                        />
                      )}
                    </Field>
                  </div>

                  <Field
                    name='opening_balance'
                    component={NumberInput}
                    label='Opening Balance'
                    placeholder='Enter opening balance'
                  />

                  <Field
                    name='total_credit'
                    component={NumberInput}
                    label='Total Credit'
                    placeholder='Enter total credit'
                  />

                  <Field
                    name='total_debit'
                    component={NumberInput}
                    label='Total Debit'
                    placeholder='Enter total debit'
                  />

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className='text-gray-600 font-medium'>Bank Statement Start Date:</label>
                    <Field name='statement_start_date'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          type='text'
                          readOnly
                          className='w-2/3 p-2 border rounded bg-gray-100'
                        />
                      )}
                    </Field>
                  </div>

                  <div className='px-2 mb-6 flex flex-col'>
                    <label className='text-gray-600 font-medium'>Bank Statement End Date:</label>
                    <Field name='statement_end_date'>
                      {({ field }) => (
                        <InputField
                          {...field}
                          type='text'
                          readOnly
                          className='w-2/3 p-2 border rounded bg-gray-100'
                        />
                      )}
                    </Field>
                  </div>
                </div>

                {/* Add/Upload Statement Button */}
                <div className='flex justify-center gap-4 mt-8'>
                  <button
                    type='button'
                    onClick={() => handleViewStatement(bankStatement?.file_url)}
                    className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                  >
                    Show Document
                  </button>

                  <button
                    type='button'
                    onClick={() => setIsBankStatementModalVisible(true)}
                    className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
                  >
                    View Bank Statement
                  </button>

                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className='border py-2 px-6 rounded bg-blue-700 text-white'
                  >
                    {isSubmitting ? 'Processing...' : 'Update Analysis'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}

      <Modal
        open={isBankStatementModalVisible}
        onCancel={() => setIsBankStatementModalVisible(false)}
        footer={null}
        centered
        width={600}
      >
        <h3 className='text-center font-bold text-xl mb-4'>Bank Statement Details</h3>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Modal>
    </Modal>
  )
}

UpdateBankStatementModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  bankStatement: PropTypes.object,
  approvedLoanId: PropTypes.string,
  loanId: PropTypes.string,
  fetchBankStatements: PropTypes.func
}

export default UpdateBankStatementModal
