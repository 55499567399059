import * as React from 'react'

const CheckIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path
      fill='#E1E1E1'
      fillRule='evenodd'
      d='M20.687 7.227a1 1 0 1 0-1.374-1.454L8.353 16.125 4.687 12.66a1 1 0 1 0-1.374 1.454l4.354 4.112a1 1 0 0 0 1.373 0z'
      clipRule='evenodd'
    />
  </svg>
)

export default CheckIcon
