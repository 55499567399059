import React from 'react'
import { PEOPLE_AT_VITTAS, PEOPLE_AT_VITTAS_TEXT, VITTAS_PEOPLE_CARD_DATA } from '../constants'
import VittasPeopleCard from './VittasPeopleCard'

const VittasPeople = () => {
  return (
    <main className='md:py-16 py-10 px-4 md:px-0 mb-10'>
      <div className='flex justify-between  items-center'>
        <h1 className='text-2xl font-bold'>{PEOPLE_AT_VITTAS}</h1>
        <div className='hidden md:flex gap-4'>
          <button>
            <i className='fas fa-arrow-left' />
          </button>
          <button>
            <i className='fas fa-arrow-right' />
          </button>
        </div>
      </div>
      <p className='md:text-lg text-sm max-w-2xl tracking-wider mt-4 leading-relaxed'>
        {PEOPLE_AT_VITTAS_TEXT}
      </p>
      <div className='md:mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-8'>
        {VITTAS_PEOPLE_CARD_DATA.map((data, index) => (
          <VittasPeopleCard
            key={index}
            paragrapgh={data.paragraph}
            name={data.name}
            role={data.role}
            image={data.image}
          />
        ))}
      </div>
      <div className='md:hidden flex justify-center gap-4'>
        <button>
          <i className='fas fa-arrow-left' />
        </button>
        <button>
          <i className='fas fa-arrow-right' />
        </button>
      </div>
    </main>
  )
}

export default VittasPeople
