import React from 'react'
import { useSelector } from 'react-redux'
import DTIAnalysis from './DTIAnalysis'

const DTIAnalysisTab = () => {
  const details = useSelector(({ applications }) => applications.appDetails)
  const bankStatementList = useSelector(
    ({ applications }) =>
      applications.appDetails?.business_bank_statement?.filter(
        doc => doc.type === 'bank_statement'
      ) || []
  )

  return (
    <DTIAnalysis
      bankStatementList={bankStatementList}
      approvedLoanId={details?.approved_data?.approved_loan_id}
      setShowModal={() => {}}
    />
  )
}

export default DTIAnalysisTab
