import React from 'react'
import PropTypes from 'prop-types'

const VittasPeopleCard = ({ paragrapgh, name, role, image }) => {
  return (
    <div className='max-w-sm rounded-xl py-8 px-3 shadow-lg bg-white'>
      <div className='px-6 py-4'>
        <div className='font-normal max-w-xs text-sm text-vdfHtexts leading-relaxed md:text-lg mb-2'>
          {paragrapgh}
        </div>
        <div className='flex items-center mt-6 gap-4'>
          <img className='w-9 h-9 rounded-full' src={image} />
          <div className=''>
            <p className='text-black text-lg font-semibold leading-relaxed'>{name}</p>
            <p className='text-light-100 text-sm'>{role}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
VittasPeopleCard.propTypes = {
  paragrapgh: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired
}

export default VittasPeopleCard
