import React from 'react'
import CTASection from './components/CTASection'
import WhyJoinUsSection from '../newsletter/components/WhyJoinUsSection'
import GetTheAppSection from '../newsletter/components/GetTheAppSection'

function CustomNewsLetter() {
  return (
    <div className='pt-16'>
      <CTASection />
      <div className='w-full p-4 md:p-16 flex flex-col gap-16'>
        <WhyJoinUsSection />
        <GetTheAppSection />
      </div>
    </div>
  )
}

export default CustomNewsLetter
