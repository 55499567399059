import React, { useEffect, useState } from 'react'
import nurse from '../../../images/nurse.jpg'
import { isValidEmail } from '../../../utils/inputValidator'
import { notification, Spin } from 'antd'
import { LeadsApi } from '../../../services'
import { useLocation, useHistory } from 'react-router-dom'

function CTASection() {
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  const history = useHistory()

  // Function to extract query parameters
  const getQueryParam = param => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get(param)
  }

  const email = getQueryParam('email')

  const handleSubmit = async () => {
    const validEmail = isValidEmail(email)

    if (!validEmail) {
      notification.error({
        message: 'Invalid Email provided',
        description: 'Oops, looks like the email provided is not valid'
      })
      return
    }

    setLoading(true)
    try {
      await LeadsApi.unsubscribeFromNewsletter(email)
      notification.success({
        message: 'Newsletter subscription',
        description:
          "Unsubscribed! You won't receive further emails, but you can resubscribe anytime."
      })
      setTimeout(() => {
        history.push('/newsletter', { replace: true })
      }, 2000)
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Something went wrong'
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const email = getQueryParam('email')
    if (!email) {
      notification.error({
        message: 'Error',
        description: 'Invalid URL, Redirecting ....'
      })
      setTimeout(() => {
        history.push('/newsletter', { replace: true })
      }, 1000)
    }
  }, [location, history])

  return (
    <div className='bg-black text-white grid grid-cols-1 md:grid-cols-2 items-center  gap-8 w-full p-4 pt-32 md:p-16 '>
      <div>
        <p className='text-3xl md:text-6xl font-bold leading-8'>Unsubscribe from our newsletter</p>
        <p className='my-6' />

        <div>
          <div className='lg:w-4/12 lg:mr-4'>
            <div className='flex justify-center gap-4 flex-col'>
              <button
                className='lg:bg-primary btn-primary-200 py-4 text-white font-bold text-xl mt-4 rounded-lg'
                onClick={handleSubmit}
              >
                {loading ? (
                  <>
                    <Spin className='text-white' /> Processing...
                  </>
                ) : (
                  'Unsubscribe'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={nurse} alt='nurse' />
      </div>
    </div>
  )
}

export default CTASection
