import React, { useEffect } from 'react'
import { Steps, notification } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

import { NEXT, applyLoanlocalStorageKey, steps } from '../../constants'
import { InputField } from '../../../../../sharedComponents/input-field'
import {
  BUSINESS_EMAIL,
  BUSINESS_INDUSTRY,
  BUSINESS_LOCATION,
  BUSINESS_NAME,
  BUSINESS_PHONE_NO,
  FOUNDED_DATE,
  INCORPORATION_DATE,
  NO_OF_EMPLOYEE,
  RC_NUMBER,
  YEAR_AT_BIZ,
  BIZ_DESCRIPTION,
  EQUIPMENT_LOAN,
  MEDICATION_LOAN,
  SELECT_BUSINESS_INDUSTRY
} from '../../../../../sharedComponents/entries/constant'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getLocalStorageLoanApplication } from '../../../../../utils/helpers'
const { Step } = Steps

/**
 * @description renders the first step of the apply for loan process
 * @param {object} param0 the component props
 * @param {object} param0.user the user/customer object to be used
 * @param {function} param0.onNext the function to be called when the next button is clicked to go to the next step
 * @param {object} values an object containing form data or values from the current step
 * @returns {JSX} the formik form component
 */
const Step1 = ({ user, onNext, savedData, disableStepThree }) => {
  const [submitting, setSubmitting] = React.useState(false)
  const history = useHistory()
  const userIdFromParams = useParams().userId

  const { data, isAdmin } = useSelector(({ auth: { authUser } }) => ({
    isAdmin: authUser.userInfo?.is_admin,
    data: authUser.userInfo?.data
  }))

  const initialValues = {
    bizName: isAdmin ? user?.business_name || '' : data.business_name || '',
    bizEmail: isAdmin ? user?.email || '' : data.email || '',
    bizPhone: isAdmin ? user?.phone_number || '' : data.phone_number || '',
    noOfEmployee: '1',
    incorporationDate: '',
    bizFound: '',
    bizDescription: '',
    bizIndustry: '',
    bizRcNo: '',
    bizYear: '',
    bizLocation: ''
  }

  const validationSchema = Yup.object().shape({
    bizName: Yup.string().required('Business name is required'),
    bizEmail: Yup.string().email('Invalid email').required('Email is required'),
    bizPhone: Yup.string().required('Phone number is required'),
    noOfEmployee: Yup.string().required('Number of employees is required'),
    incorporationDate: Yup.date().required('Incorporation date is required'),
    bizFound: Yup.date().required('Founded date is required'),
    bizDescription: Yup.string().required('Business description is required'),
    bizIndustry: Yup.string().required('Business industry is required'),
    bizRcNo: Yup.string().required('RC number is required'),
    bizYear: Yup.string().required('Year at business is required'),
    bizLocation: Yup.string().required('Business location is required')
  })

  /**
   * @description handles the form submission for step 1
   * @param {*} values the form values
   * @returns {void}
   */
  const handleSubmit = async values => {
    setSubmitting(true)
    const stepOnePayload = {
      ...values,
      loan_id: savedData?.loan_id || '',
      userId: isAdmin ? user?.id || '' : data?.id
    }

    if (!stepOnePayload.loan_id) {
      delete stepOnePayload.loan_id
    }

    const payload = !_.isEqual(stepOnePayload, savedData) ? stepOnePayload : ''
    const nextStatus = await onNext(payload, 1)

    if (!nextStatus) {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    let isMounted = true // used to track whether the component is still mounted.
    const storedData = getLocalStorageLoanApplication()
    const storedValues = storedData?.[steps[1]]
    if (storedValues && storedValues?.userId !== userIdFromParams) {
      window.localStorage.removeItem(applyLoanlocalStorageKey)
      if (isMounted) {
        //  prevents any operations from being executed if the component has been unmounted
        notification.warn({
          message: 'An error occurred, please start afresh',
          onClose: () => history.push('/admin-pending')
        })
      }
    }
    return () => {
      isMounted = false
    } // Adding a cleanup function that runs when the component is unmounted.
  }, [history, userIdFromParams])

  return (
    <div className='md:my-12'>
      <div className='w-full md:px-12 mx-auto'>
        <Steps current={0} className='custom-steps'>
          <Step title='Business information' />
          <Step title='Business Documents' disabled />
          {!disableStepThree ? <Step title='Business Signatories' disabled /> : null}
          <Step title='Loan Details' disabled />
        </Steps>
      </div>

      <div className=''>
        <div className='w-full mx-auto md:px-6 rounded-xl'>
          <h1 className='md:ml-6 md:mt-8 md:text-lg'>Kindly provide the following documents:</h1>
          <Formik
            initialValues={savedData || initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <Form>
              <div className='flex flex-wrap mx-1 lg:mx-2 md:mx-auto'>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='bizName'
                    label={<label className='text-black text-base'>{BUSINESS_NAME}</label>}
                    placeholder={BUSINESS_NAME.slice(0, -1)}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='bizName' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='email'
                    name='bizEmail'
                    label={<label className='text-black text-base'>{BUSINESS_EMAIL}</label>}
                    placeholder={BUSINESS_EMAIL.slice(0, -1)}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='bizEmail' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='bizPhone'
                    maxLength='15'
                    label={<label className='text-black text-base'>{BUSINESS_PHONE_NO}</label>}
                    placeholder={BUSINESS_PHONE_NO.slice(0, -1)}
                    className='form-control bg-white'
                    normalize={value => value.replace(/\s/g, '')}
                  />
                  <ErrorMessage name='bizPhone' component='small' className='text-red-500' />
                </div>
                <div className='w-full md:w-1/3 md:px-4 mt-8'>
                  <label className='ml-2 text-black text-base leading-8'>{BUSINESS_INDUSTRY}</label>
                  <Field as='select' name='bizIndustry' className='form-control bg-white'>
                    {[SELECT_BUSINESS_INDUSTRY, EQUIPMENT_LOAN, MEDICATION_LOAN].map((type, id) => (
                      <option key={id} value={type === SELECT_BUSINESS_INDUSTRY ? '' : type}>
                        {type}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='bizIndustry' component='small' className='text-red-500' />
                </div>
                <div className='w-full md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    maxLength='15'
                    name='bizRcNo'
                    label={<label className='text-black text-base'>{RC_NUMBER}</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='bizRcNo' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='noOfEmployee'
                    maxLength='7'
                    label={<label className='text-black text-base'>{NO_OF_EMPLOYEE}</label>}
                    placeholder={NO_OF_EMPLOYEE.slice(0, -1)}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='noOfEmployee' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='bizYear'
                    maxLength='10'
                    label={<label className='text-black text-base'>{YEAR_AT_BIZ}</label>}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='bizYear' component='small' className='text-red-500' />
                </div>
                <div className='w-full sm:-1/2 md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='date'
                    label={<label className='text-black text-base'>{INCORPORATION_DATE}</label>}
                    placeholder={INCORPORATION_DATE.slice(0, -1)}
                    name='incorporationDate'
                    max={new Date().toISOString().slice(0, 10)}
                    className='form-control bg-white'
                  />
                  <ErrorMessage
                    name='incorporationDate'
                    component='small'
                    className='text-red-500'
                  />
                </div>
                <div className='w-full md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='date'
                    label={<label className='text-black text-base'>{FOUNDED_DATE}</label>}
                    name='bizFound'
                    max={new Date().toISOString().slice(0, 10)}
                    className='form-control bg-white'
                  />
                  <ErrorMessage name='bizFound' component='small' className='text-red-500' />
                </div>
                <div className='w-full md:w-1/3 md:px-4 mt-8'>
                  <Field
                    as={InputField}
                    type='text'
                    name='bizDescription'
                    label={<label className='text-black text-base'>{BIZ_DESCRIPTION}</label>}
                    className='form-control py-8 bg-white'
                  />
                  <ErrorMessage name='bizDescription' component='small' className='text-red-500' />
                </div>
                <div className='w-full md:max-w-sm px-1 md:px-4 mt-8'>
                  <label className='text-black text-base leading-8'>{BUSINESS_LOCATION}</label>
                  <Field
                    as='textarea'
                    name='bizLocation'
                    rows='6'
                    className='resize-none border rounded-md w-full max-w-md appearance-none bg-white border-b-2 mr-3 py-2 px-2 leading-tight  mt-1 focus:outline-none  border-gray-400'
                  />
                  <ErrorMessage name='bizLocation' component='small' className='text-red-500' />
                </div>
              </div>
              <div className='w-full flex mt-6 mb-8 justify-start pr-10 md:ml-5'>
                <button className='btn-primary mb-6 bg-primary px-8 py-2  text-white' type='submit'>
                  {submitting ? 'Sending...' : NEXT} <i className='fa fa-arrow-right ml-2' />
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}

Step1.propTypes = {
  onNext: PropTypes.func.isRequired,
  user: PropTypes.shape({
    phone_number: PropTypes.string,
    email: PropTypes.string,
    business_name: PropTypes.string,
    id: PropTypes.string
  }),
  savedData: PropTypes.shape({
    bizName: PropTypes.string,
    bizEmail: PropTypes.string,
    bizPhone: PropTypes.string,
    noOfEmployee: PropTypes.string,
    incorporationDate: PropTypes.string,
    bizFound: PropTypes.string,
    bizDescription: PropTypes.string,
    bizIndustry: PropTypes.string,
    bizRcNo: PropTypes.string,
    bizYear: PropTypes.string,
    bizLocation: PropTypes.string,
    loan_id: PropTypes.string
  }),
  disableStepThree: PropTypes.bool
}

export default Step1
