/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Steps, notification, Spin, Select } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { NEXT, applyLoanlocalStorageKey, steps } from '../../constants'
import InfoModal from '../modal/InfoModal'
import {
  AOA,
  businessLicense,
  bankStatement,
  PharmaceuticalLicense,
  MOA,
  cac7,
  cac3,
  cac2,
  cacCertificate,
  memart,
  applicationDetails
} from '../../../../../sharedComponents/entries/constant'
import { validateFileTypes } from '../../../../../utils/helpers'
import { Upload } from '../../../../../services'
import { useSelector } from 'react-redux'
import { dispatch } from '../../../../../redux/store'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PreviewModal from '../modal/PreviewModal'
import DocumentUploadCard from '../../../../../components/DocumentUploadCard'

const { Step } = Steps

const DOCUMENT_PERIODS = {
  T_2016_BACKWARDS: 'T_2016_BACKWARDS',
  T_2017_TILL_DATE: 'T_2017_TILL_DATE'
}

const DOCUMENT_CONFIGS = {
  T_2017_TILL_DATE: [
    {
      id: 'aoa',
      title: 'Article of Association',
      description: AOA
    },
    {
      id: 'pharmacist_license',
      title: 'Pharmaceutical license',
      description: PharmaceuticalLicense
    },
    {
      id: 'business_licenses',
      title: 'Business License',
      description: businessLicense
    },
    {
      id: 'bank_statement',
      title: '6 Month Bank Statement',
      description: bankStatement
    },
    {
      id: 'moa',
      title: 'Memorandum of Association (MOA)',
      description: MOA
    },
    {
      id: 'cac_7',
      title: 'CAC Form 7 (Appointment of Directors)',
      description: cac7
    },
    {
      id: 'cac_3',
      title: 'CAC Form 3 (change of registered address)',
      description: cac3,
      isOptional: true
    },
    {
      id: 'cac_2',
      title: 'CAC Form 2 (Statement of share capital)',
      description: cac2
    }
  ],
  T_2016_BACKWARDS: [
    {
      id: 'pharmacist_license',
      title: 'Pharmaceutical license',
      description: PharmaceuticalLicense
    },
    {
      id: 'business_licenses',
      title: 'Business License',
      description: businessLicense
    },
    {
      id: 'bank_statement',
      title: '6 Month Bank Statement',
      description: bankStatement
    },
    {
      id: 'moa',
      title: 'Memorandum of Association (MOA)',
      description: MOA
    },
    {
      id: 'cac_certificate',
      title: 'CAC Certificate',
      description: cacCertificate
    },
    {
      id: 'memat',
      title: 'MEMART',
      description: memart
    },
    {
      id: 'application_details',
      title: 'Application Details',
      description: applicationDetails
    }
  ]
}

const Step2 = ({ onNext, onGoBack, savedData, clearCurrentStep, disableStepThree }) => {
  const [fileError, setFileError] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCard, setSelectedCard] = useState({
    title: '',
    description: ''
  })
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [previewFileUrl, setPreviewFileUrl] = useState('')
  const [fileUploaded, setFileUploaded] = useState({
    aoa: false,
    pharmacist_license: false,
    business_licenses: false,
    bank_statement: false,
    moa: false,
    cac_7: false,
    cac_2: false,
    cac_certificate: false,
    memat: false,
    application_details: false
  })

  const [fileUrls, setFileUrls] = useState({
    aoa: '',
    pharmacist_license: '',
    business_licenses: '',
    bank_statement: '',
    moa: '',
    cac_7: '',
    cac_2: '',
    cac_certificate: '',
    memat: '',
    application_details: ''
  })

  const [documentPeriod, setDocumentPeriod] = useState(DOCUMENT_PERIODS.T_2017_TILL_DATE)

  const userIdFromParams = useParams().userId
  const { isAdmin, token, permissions, docsSaving } = useSelector(
    ({ auth: { authUser }, loading }) => ({
      isAdmin: authUser.userInfo?.is_admin,
      token: authUser.token,
      permissions: authUser.permissions,
      docsSaving:
        loading.effects.applications.customerUploadDocument ||
        loading.effects.applications.adminUploadDocument
    })
  )

  /**
   * @description handles the file change event
   * @param {File} event the file change event
   * @param {*} cardId the id of the card or type of docs to upload
   * @returns {void}
   */
  const handleFileChange = (event, cardId) => {
    const file = event.target.files[0]

    setFileUploaded(prevState => ({
      ...prevState,
      [`${cardId}loading`]: true
    }))

    // Check if file is valid
    if (!validateFileTypes(file)) {
      setFileUploaded(prevState => ({
        ...prevState,
        [`${cardId}loading`]: false
      }))
      notification.error({
        message: 'Invalid file type',
        description:
          'Please upload a valid file type, we only accept images, pdfs, excel files and docs'
      })
      return
    }

    // upload file to the cloud
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    formData.append('file', file)

    Upload.uploadFile(formData)
      .then(({ data: { data } }) => {
        // we added the IDs to see if frontend will determine the IDs of the docs. might be removed if its not working
        const dataUrl = `${uuidv4()}|${data.url}|${file.name}`

        // Save file name to state
        setFileUrls(prevState => ({
          ...prevState,
          [cardId]: dataUrl // Save the data URL and id instead of the Blob URL
        }))

        // Save file to local storage
        const loanObjectString = window.localStorage.getItem(applyLoanlocalStorageKey)

        if (loanObjectString) {
          const loanObject = {
            ...JSON.parse(loanObjectString),

            [steps[2]]: {
              ...JSON.parse(loanObjectString)?.businessDocuments,
              fileUrls: {
                ...JSON.parse(loanObjectString)?.businessDocuments?.fileUrls,
                [cardId]: dataUrl
              }
            }
          }

          window.localStorage.setItem(applyLoanlocalStorageKey, JSON.stringify(loanObject))
        }

        setFileError(prevState => ({
          ...prevState,
          [cardId]: ''
        }))

        // Set file uploaded to true
        setFileUploaded(prevState => ({
          ...prevState,
          [cardId]: true
        }))
        setPreviewFileUrl(dataUrl)
      })
      .catch(error => {
        notification.error({
          message: 'Error',
          description: `An error occured while uploading file, ${error?.message}`
        })
      })
      .finally(() => {
        setFileUploaded(prevState => ({
          ...prevState,
          [`${cardId}loading`]: false
        }))
      })

    // Set file error if file is not uploaded
    if (!file) {
      setFileError(prevState => ({
        ...prevState,
        [cardId]: 'File is required!'
      }))
    }
  }

  const handleInfoClick = (title, description) => {
    setSelectedCard({ title, description })
    setInfoModalVisible(true)
  }

  const handlePreview = fileUrl => {
    setPreviewFileUrl(fileUrl)
    setModalVisible(true)
  }

  const handleSubmit = async () => {
    let fileErrorPresent = false
    const newFileError = { ...fileError }

    // Get required documents based on period
    const requiredDocs = DOCUMENT_CONFIGS[documentPeriod]
      .filter(doc => !doc.isOptional)
      .map(doc => doc.id)

    // Check if any required file uploads are missing
    requiredDocs.forEach(docId => {
      if (!fileUploaded[docId] && !newFileError[docId]) {
        const doc = DOCUMENT_CONFIGS[documentPeriod].find(d => d.id === docId)
        newFileError[docId] = `${doc.title} is required!`
        fileErrorPresent = true
      }
    })

    // Updating the file error state
    setFileError(newFileError)

    if (fileErrorPresent) {
      notification.error({
        message: 'Missing Documents',
        description: 'Please upload all required documents before proceeding'
      })
      return
    }

    // if the user changed the file urls, upload the new files
    if (!_.isEqual(fileUrls, savedData?.fileUrls || savedData)) {
      // prepare the docs for upload for the specific customers profile
      const docsForUpload = Object.entries(fileUrls)
        .filter(([key]) => fileUrls[key]) // Only upload files that exist
        .map(([key, value]) => ({
          file_url: value.split('|')[1],
          type: key,
          title: value.split('|')[2],
          customer_id: userIdFromParams
        }))

      let docsIds = []
      try {
        // if it is not an admin, upload the docs for the customer
        if (!isAdmin) {
          docsIds = await dispatch.applications.customerUploadDocument({
            token,
            permissions,
            formValue: docsForUpload
          })
        } else {
          docsIds = await dispatch.applications.adminUploadDocument(docsForUpload)
        }

        if (!docsSaving) {
          return await onNext(
            {
              fileUrls,
              docsIds,
              document_period: documentPeriod
            },
            2
          )
        }
      } catch (error) {
        notification.error({
          message: 'Upload Error',
          description: `Failed to upload documents: ${error.message}`
        })
      }
    } else {
      // If no new files to upload, proceed to next step with just the document period
      return await onNext(
        {
          document_period: documentPeriod,
          fileUrls: savedData?.fileUrls || savedData
        },
        2
      )
    }
  }

  const handlePeriodChange = value => {
    setDocumentPeriod(value)
    // Clear all uploaded files when period changes
    setFileUrls({})
    setFileUploaded({})
    setFileError({})
  }

  useEffect(() => {
    // Check for previously uploaded files in local storage
    if (savedData) {
      setFileUrls(savedData?.fileUrls || savedData)
      // convert savedData to object of boolean values
      setFileUploaded(
        Object.entries(savedData?.fileUrls || savedData || {}).reduce((acc, [key, value]) => {
          acc[key] = !!value
          return acc
        }, {})
      )
    } else {
      setFileUrls({
        aoa: '',
        pharmacist_license: '',
        business_licenses: '',
        bank_statement: '',
        moa: '',
        cac_7: '',
        cac_3: '',
        cac_2: '',
        cac_certificate: '',
        memat: '',
        application_details: ''
      })
      setFileUploaded({
        aoa: false,
        pharmacist_license: false,
        business_licenses: false,
        bank_statement: false,
        moa: false,
        cac_7: false,
        cac_3: false,
        cac_2: false,
        cac_certificate: false,
        memat: false,
        application_details: false
      })
    }
  }, [savedData])

  // Add a function to check if all required documents are uploaded
  const areAllRequiredDocsUploaded = () => {
    const requiredDocs = DOCUMENT_CONFIGS[documentPeriod]
      .filter(doc => !doc.isOptional)
      .map(doc => doc.id)

    return requiredDocs.every(docId => fileUploaded[docId])
  }

  return (
    <div className='md:my-12'>
      <Steps current={1} className='custom-steps'>
        <Step title='Business information' />
        <Step title='Business Documents' disabled />
        {!disableStepThree ? <Step title='Business Signatories' disabled /> : null}
        <Step title='Loan Details' disabled />
      </Steps>
      <div className='my-4'>
        <div className='grid grid-cols-5'>
          <div className='col-span-2'>
            <label className='block text-sm font-medium text-gray-700 mb-2'>
              Select document period
            </label>
            <Select
              value={documentPeriod}
              onChange={handlePeriodChange}
              className='w-full'
              options={[
                { value: DOCUMENT_PERIODS.T_2017_TILL_DATE, label: '2017 till date' },
                { value: DOCUMENT_PERIODS.T_2016_BACKWARDS, label: '2016 backwards' }
              ]}
            />
          </div>
          <button
            onClick={() => clearCurrentStep(2)}
            className='text-blue-500 text-lg mt-10 text-right col-start-4'
          >
            Clear all information
          </button>
        </div>
        <div className='mt-6'>
          <h1>Kindly upload a clear document below. (JPEG/PDF File size: 30mb max.)</h1>
          <div className='grid w-full max-w-5xl md:gap-14 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:mt-7'>
            {DOCUMENT_CONFIGS[documentPeriod].map(doc => (
              <DocumentUploadCard
                key={doc.id}
                title={doc.title}
                description={doc.description}
                documentId={doc.id}
                isUploaded={fileUploaded[doc.id]}
                isLoading={fileUploaded[`${doc.id}loading`]}
                fileUrl={fileUrls[doc.id]}
                onInfoClick={handleInfoClick}
                onFileChange={handleFileChange}
                onPreview={handlePreview}
                fileError={fileError[doc.id]}
                isOptional={doc.isOptional}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='w-full flex py-8 mt-8 mb-8 justify-start gap-8 md:ml-5'>
        <button className='px-5 text-base text-primary' onClick={() => onGoBack(1)}>
          Go back
        </button>{' '}
        <button
          className={`btn-primary px-8 py-2 text-white ${
            !areAllRequiredDocsUploaded() ? 'opacity-50 cursor-not-allowed' : 'bg-primary'
          }`}
          onClick={handleSubmit}
          disabled={!areAllRequiredDocsUploaded() || docsSaving}
        >
          {NEXT} {docsSaving ? <Spin size='small' /> : <i className='fa fa-arrow-right ml-2' />}
        </button>{' '}
      </div>
      {modalVisible && (
        <PreviewModal
          modalVisible={modalVisible}
          previewFileUrl={previewFileUrl.split('|')[1]}
          setModalVisible={setModalVisible}
        />
      )}
      <InfoModal
        visible={infoModalVisible}
        onCancel={() => setInfoModalVisible(false)}
        title={selectedCard.title}
        description={selectedCard.description}
      />
    </div>
  )
}

Step2.propTypes = {
  onNext: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  savedData: PropTypes.object,
  clearCurrentStep: PropTypes.func,
  disableStepThree: PropTypes.bool
}

export default Step2
