import React, { useEffect, useState } from 'react'
import { InputField } from '../../../../sharedComponents/input-field'
import PropTypes from 'prop-types'
import { adminServices } from '../../../../services'
import { Spin, notification } from 'antd'
import { useSelector } from 'react-redux'
import { NumericFormat } from 'react-number-format'

// Custom Input component that uses NumericFormat
const NumberInput = ({
  value,
  onChange,
  label,
  placeholder,
  disabled,
  required,
  className,
  prefix = '₦'
}) => {
  return (
    <NumericFormat
      thousandSeparator=','
      thousandsGroupStyle='thousand'
      allowLeadingZeros={false}
      value={value}
      onValueChange={values => {
        onChange({ target: { value: values.value } })
      }}
      customInput={InputField}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      className={className}
      prefix={prefix}
    />
  )
}

const DTIAnalysis = ({ bankStatementList, approvedLoanId, setShowModal }) => {
  const [riskAnalysisId, setRiskAnalysisId] = useState(null)
  const [fundsAvailable, setFundsAvailable] = useState(0)
  const [dtiRatioAnalysis, setDtiRatioAnalysis] = useState(0)
  const [loanRequest, setLoanRequest] = useState(0)
  const [interest, setInterest] = useState(0)
  const [principalAndInterest, setPrincipalAndInterest] = useState(0)
  const [principalAndInterestMonthly, setPrincipalAndInterestMonthly] = useState(0)
  const [totalExternalCommitment, setTotalExternalCommitment] = useState('')
  const [loanEligibilityAmount, setLoanEligilityAmount] = useState(0)
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState(false)
  const [processingRiskLoading, setProcessingRiskLoading] = useState(true)
  const [isApproving, setIsApproving] = useState(false)
  const [isDeclining, setIsDeclining] = useState(false)
  const details = useSelector(({ applications }) => applications.appDetails)

  const handleInputChange = setter => event => {
    setter(event.target.value)
  }

  const validateRequiredFields = () => {
    if (!comment.trim()) {
      notification.error({
        message: 'Error',
        description: 'Comment is required'
      })
      return false
    }

    if (!totalExternalCommitment) {
      notification.error({
        message: 'Error',
        description: 'External Commitment is required'
      })
      return false
    }

    if (!loanEligibilityAmount) {
      notification.error({
        message: 'Error',
        description: 'Loan Eligibility Amount is required'
      })
      return false
    }

    return true
  }

  const handleApproval = async () => {
    if (!validateRequiredFields()) return
    if (!riskAnalysisId) return

    setIsApproving(true)
    const payload = {
      status: 'PASS',
      is_approved: true,
      eligibility_amount: loanEligibilityAmount,
      total_external_commitment: totalExternalCommitment,
      comment: comment
    }
    try {
      await adminServices.riskAnalysisApprovalAndDecline(riskAnalysisId, payload)
      setShowModal(false)
      notification.success({
        message: 'Success',
        description: 'Risk analysis approved successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Failed to approve risk analysis'
      })
    } finally {
      setIsApproving(false)
    }
  }

  const handleRejection = async () => {
    if (!validateRequiredFields()) return
    if (!riskAnalysisId) return

    setIsDeclining(true)
    const payload = {
      status: 'FAIL',
      is_approved: false,
      eligibility_amount: loanEligibilityAmount,
      total_external_commitment: totalExternalCommitment,
      comment: comment
    }
    try {
      await adminServices.riskAnalysisApprovalAndDecline(riskAnalysisId, payload)
      setShowModal(false)
      notification.success({
        message: 'Success',
        description: 'Risk analysis declined successfully'
      })
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || 'Failed to decline risk analysis'
      })
    } finally {
      setIsDeclining(false)
    }
  }

  useEffect(() => {
    const processRiskAnalysis = async () => {
      const bankStatementsFormatted = bankStatementList?.map(item => {
        return {
          bank_name: item.bank_name ?? 'No Bank Name',
          opening_balance: item.balance ?? 0,
          total_credit: 0,
          total_debit: 0,
          acc_no: item.account_number ?? '0000000000',
          total_turnover: 0,
          average_turnover: 0,
          document_url: item?.file_url
        }
      })

      const riskAnalysisResponse = await adminServices
        .postRiskAnalysis({
          loan_id: approvedLoanId,
          bank_statements: bankStatementsFormatted
        })
        .catch(() => {})
        .finally(() => {
          setProcessingRiskLoading(false)
        })

      const riskAnalysisData = riskAnalysisResponse?.data

      setRiskAnalysisId(riskAnalysisData?.id)

      const finalResponse = {
        approved_loan_id: approvedLoanId,
        status: riskAnalysisData?.status,
        dti_analysis: riskAnalysisData?.dti_analysis,
        available_funds: riskAnalysisData?.available_funds,
        interest: riskAnalysisData?.interest,
        principal_and_interest: riskAnalysisData?.principal_and_interest,
        principal_and_interest_monthly: riskAnalysisData?.principal_and_interest_monthly,
        total_external_commitment: riskAnalysisData?.total_external_commitment,
        eligibility_amount: riskAnalysisData?.eligibility_amount,
        is_approved: riskAnalysisData?.is_approved
      }

      if (finalResponse) {
        setFundsAvailable(Number(riskAnalysisData?.available_funds) || 0)
        setDtiRatioAnalysis(Number(riskAnalysisData?.dti_analysis) || 0)
        setInterest(Number(riskAnalysisData?.interest) || 0)
        setPrincipalAndInterest(Number(riskAnalysisData?.principal_and_interest) || 0)
        setPrincipalAndInterestMonthly(
          Number(riskAnalysisData?.principal_and_interest_monthly) || 0
        )
        setTotalExternalCommitment(Number(riskAnalysisData?.total_external_commitment) || 0)
        setLoanEligilityAmount(Number(riskAnalysisData?.eligibility_amount) || 0)
        setLoanRequest(Number(details?.approved_data?.loan_principal_amount_fixed) || 0)
        setStatus(riskAnalysisData?.status === 'pass')
      }
    }

    if (approvedLoanId && bankStatementList?.length > 0) {
      processRiskAnalysis()
    }
  }, [approvedLoanId, bankStatementList])

  if (processingRiskLoading) {
    return (
      <div className='flex mt-4 ml-2'>
        <Spin size='default' />
        &nbsp;<p className='pl-3'>Loading Your Risk Analysis. Kindly hold on ...</p>
      </div>
    )
  }

  return (
    <div>
      <div className='flex flex-wrap'>
        <div className='w-full sm:w-1/2 md:px-4'>
          <NumberInput
            onChange={handleInputChange(setFundsAvailable)}
            value={fundsAvailable}
            label='Funds available'
            placeholder='3.00'
            className='form-control bg-white'
          />
        </div>
        <div className='w-full sm:w-1/2 md:px-4'>
          <NumberInput
            onChange={handleInputChange(setDtiRatioAnalysis)}
            value={dtiRatioAnalysis}
            label='DTI ratio analysis at 36%'
            placeholder='12 Month'
            className='form-control bg-white'
            prefix=''
          />
        </div>
        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setLoanRequest)}
            value={loanRequest}
            label='Loan Request'
            placeholder='Enter loan request'
            className='form-control bg-white'
            disabled
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setInterest)}
            value={interest}
            label='Interest (12 Month)'
            placeholder='12 000'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setPrincipalAndInterest)}
            value={principalAndInterest}
            label='Principal and interest'
            placeholder='Enter principal and interest'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setPrincipalAndInterestMonthly)}
            value={principalAndInterestMonthly}
            label='Principal and interest monthly'
            placeholder='Enter principal and interest monthly'
            className='form-control bg-white'
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setTotalExternalCommitment)}
            value={totalExternalCommitment}
            label='External Commitment *'
            placeholder='Enter external commitment'
            className='form-control bg-white'
            required
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <NumberInput
            onChange={handleInputChange(setLoanEligilityAmount)}
            value={loanEligibilityAmount}
            label='Loan Eligibility Amount *'
            placeholder='Enter loan eligibility amount'
            className='form-control bg-white'
            required
          />
        </div>

        <div className='w-full sm:w-1/2 md:px-4 mt-8'>
          <InputField
            onChange={handleInputChange(setComment)}
            value={comment}
            type='text'
            name='comment'
            label='Comment *'
            placeholder='Enter comment here...'
            className='form-control bg-white'
            required
          />
        </div>

        <div className='inline-flex flex-col md:px-4 mt-8'>
          <div>
            <p>Status *</p>
            <div
              className={
                !status
                  ? 'bg-red-500 py-1 px-4 rounded-full text-white font-semibold mt-'
                  : 'bg-lightGreen py-1 px-4 rounded-full text-white font-semibold mt-2'
              }
            >
              {status ? 'Approved' : 'Failed'}
            </div>
          </div>
        </div>
      </div>
      <div className='flex mt-5 gap-4 justify-center'>
        <button
          type='button'
          onClick={handleApproval}
          disabled={isApproving}
          className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
        >
          {isApproving ? 'Processing...' : 'Approve'}
        </button>
        <button
          type='button'
          onClick={handleRejection}
          disabled={isDeclining}
          className='bg-transparent border text-newGray py-2 px-6 rounded hover:bg-blue-700 hover:text-white'
        >
          {isDeclining ? 'Processing...' : 'Decline'}
        </button>
      </div>
    </div>
  )
}

// Add PropTypes for NumberInput
NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  prefix: PropTypes.string
}

DTIAnalysis.propTypes = {
  bankStatementList: PropTypes.array,
  approvedLoanId: PropTypes.string,
  setShowModal: PropTypes.func
}

export default DTIAnalysis
