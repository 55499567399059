import React, { useState, useEffect } from 'react'
import { notification, Table, Button, Modal, Input, Badge, Spin } from 'antd'
import { DashboardLayout } from '../../sharedComponents/dashboardLayout'
import { adminServices } from '../../services'
import { useHistory } from 'react-router-dom'
import { ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons'

const Marketing = () => {
  const [loading, setLoading] = useState(false)
  const [subscribers, setSubscribers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalSubscribers, setTotalSubscribers] = useState(0)
  const [searchEmail, setSearchEmail] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedSubscriber, setSelectedSubscriber] = useState(null)
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const history = useHistory()

  useEffect(() => {
    fetchSubscribers(currentPage)
  }, [currentPage])

  const fetchSubscribers = async (page, email) => {
    setLoading(true)
    try {
      const response = await adminServices.adminGetNewsletterSubscribers(page, email)
      if (response?.data?.success) {
        setSubscribers(response.data.data.subscriptions || [])
        setTotalSubscribers(response.data.data.total || 0)
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch subscribers'
        })
      }
    } catch (error) {
      console.error('Error fetching subscribers:', error)
      notification.error({
        message: 'Error',
        description: 'An error occurred while fetching subscribers'
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    setDeleting(true)
    try {
      const response = await adminServices.adminDeleteNewsletterSubscriber(selectedSubscriberId)
      if (response?.data?.success) {
        notification.success({
          message: 'Success',
          description: 'Subscriber deleted successfully'
        })

        setSearchEmail('')
        fetchSubscribers(currentPage, '')
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to delete subscriber'
        })
      }
    } catch (error) {
      console.error('Error deleting subscriber:', error)
      notification.error({
        message: 'Error',
        description: 'An error occurred while deleting the subscriber'
      })
    } finally {
      setDeleting(false)
      setIsModalVisible(false)
    }
  }

  const showDeleteModal = (email, subscriptionId) => {
    setSelectedSubscriber(email)
    setSelectedSubscriberId(subscriptionId)
    setIsModalVisible(true)
  }

  const resetSearch = () => {
    setSearchEmail('')
    fetchSubscribers(1, '')
  }

  const handleSearch = () => {
    if (searchEmail.trim() === '') {
      notification.error({
        message: 'Error',
        description: 'Please enter at least one character'
      })
      return
    }
    fetchSubscribers(1, searchEmail)
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'is_subscribe',
      key: 'is_subscribe',
      render: isSubscribe => (
        <Badge
          status={isSubscribe ? 'success' : 'error'}
          text={isSubscribe ? 'Subscribed' : 'Not Subscribed'}
        />
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button
          type='link'
          onClick={() => showDeleteModal(record.email, record.id)}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        />
      )
    }
  ]

  return (
    <DashboardLayout>
      <div className='p-6'>
        <div className='flex justify-between items-center mb-6'>
          <div className='flex items-center'>
            <Input
              placeholder='Search by email'
              value={searchEmail}
              onChange={e => setSearchEmail(e.target.value)}
              style={{ width: 300, marginRight: 10 }}
            />
            <Button onClick={handleSearch} type='primary' className='mr-2'>
              Search
            </Button>
            <Button onClick={resetSearch}>Reset</Button>
          </div>
          <Button
            type='primary'
            className='bg-primary border-none'
            onClick={() => history.push('/marketing/create-newsletter')}
          >
            Create Newsletter
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={subscribers}
          loading={loading}
          rowKey='email'
          pagination={{
            current: currentPage,
            total: totalSubscribers,
            pageSize: 10,
            onChange: page => setCurrentPage(page),
            showSizeChanger: false,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} subscribers`
          }}
        />

        <Modal
          title='Confirm Deletion'
          visible={isModalVisible}
          onOk={handleDelete}
          onCancel={() => setIsModalVisible(false)}
          okText={deleting ? <Spin color='white' size='small' /> : 'Delete'}
          okButtonProps={{ danger: true }}
        >
          <div className='flex items-center'>
            <ExclamationCircleOutlined
              style={{ fontSize: '16px', color: '#faad14', marginRight: '10px' }}
            />
            <p>
              Are you sure you want to delete this subscriber <b>{selectedSubscriber}</b>?
            </p>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  )
}

export default Marketing
