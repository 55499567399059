import React from 'react'

function WhyJoinUsSection() {
  const reasons = [
    'Get exclusive updates on medical loan management solutions.',
    'Access insights into innovative healthcare financing trends.',
    'Be the first to learn about new features and tools.'
  ]
  return (
    <div>
      <div className=''>
        <h3 className='text-black font-dm-serif-display text-xl font-bold tracking-wider lg:leading-loose lg:text-3xl'>
          Why Join the Vittas Community?
        </h3>
        <ul className='text-base text-Blackk lg:text-lg tracking-wider lg:leading-loose mt-4 space-y-6'>
          {reasons.map((item, index) => (
            <li key={index} className='flex items-center gap-2'>
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='14'
                  fill='none'
                  viewBox='0 0 18 14'
                >
                  <path
                    fill='#000'
                    fillRule='evenodd'
                    d='M17.687 2.227A1 1 0 1 0 16.313.773L5.353 11.125 1.687 7.66A1 1 0 1 0 .313 9.115l4.354 4.112a1 1 0 0 0 1.373 0z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>{' '}
              <span>{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default WhyJoinUsSection
