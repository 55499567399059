import React from 'react'
import CheckIcon from './CheckIcon'
import PropTypes from 'prop-types'

function InsightBlockSection({ title }) {
  return (
    <div className='flex items-center gap-2'>
      <div>
        <CheckIcon />
      </div>
      <p className='text-16px'>{title}</p>
    </div>
  )
}

InsightBlockSection.propTypes = {
  title: PropTypes.string.isRequired
}

export default InsightBlockSection
