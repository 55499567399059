import React, { useState } from 'react'
import DirectDebitCheckModal from '../../pages/admin-pages/applications/modal/DirectDebitCheckModal'
import DirectDebitFormModal from '../../pages/admin-pages/applications/modal/DirectDebitFormModal'
import DirectDebitCheckStatusModal from '../../pages/admin-pages/applications/modal/DirectDebitCheckStatusModal'
import PaystackDirectDebitFormModal from '../../pages/admin-pages/applications/modal/PaystackDirectDebitFormModal'
import PropTypes from 'prop-types'

function DirectDebitSection({ userIdFromParams, loanId }) {
  // Direct Debit
  const [modalDDVisible, setModalDDVisible] = useState(false)
  const [modalDDFormVisible, setModalDDFormVisible] = useState(false)
  const [modalDDStatusVisible, setModalDDStatusVisible] = useState(false)

  const [selectedUsername, setSelectedUsername] = useState(null)
  const [userId, setUserId] = useState(null)
  const [bvn, setBvn] = useState(null)
  const [email] = useState(null)

  const [link, setLink] = useState(null)
  const [provider, setProvider] = useState(null)

  const closeAllDDModals = () => {
    setModalDDVisible(false)
    setModalDDFormVisible(false)
    setModalDDStatusVisible(false)
  }

  return (
    <div>
      <p
        className='hover:text-blue-600 w-full text-left nav-sm-link cursor-pointer'
        onClick={() => setModalDDVisible(true)}
      >
        Direct Debit
      </p>

      {/* Direct Debit */}
      <DirectDebitCheckModal
        visible={modalDDVisible}
        onCancel={closeAllDDModals}
        userIdFromParams={userIdFromParams}
        action={(username, userId, bvn, consentLink, selectedProvider) => {
          closeAllDDModals()
          setModalDDFormVisible(true)
          setSelectedUsername(username)
          setLink(consentLink)
          setUserId(userId)
          setBvn(bvn)
          setProvider(selectedProvider)
        }}
      />

      {/* Conditionally render the appropriate form modal */}
      {provider === 'paystack' ? (
        <PaystackDirectDebitFormModal
          visible={modalDDFormVisible}
          onCancel={closeAllDDModals}
          bvn={bvn}
          userId={userId}
          email={email}
          username={selectedUsername}
          action={consentLink => {
            closeAllDDModals()
            setModalDDStatusVisible(true)
            setLink(consentLink)
          }}
        />
      ) : (
        <DirectDebitFormModal
          visible={modalDDFormVisible}
          onCancel={closeAllDDModals}
          bvn={bvn}
          userId={userId}
          action={consentLink => {
            closeAllDDModals()
            setModalDDStatusVisible(true)
            setLink(consentLink)
          }}
          loanId={loanId}
        />
      )}

      <DirectDebitCheckStatusModal
        visible={modalDDStatusVisible}
        onCancel={closeAllDDModals}
        propData={{
          userId,
          link,
          username: selectedUsername
        }}
      />
    </div>
  )
}

DirectDebitSection.propTypes = {
  userIdFromParams: PropTypes.string,
  loanId: PropTypes.string
}

export default DirectDebitSection
