/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification } from 'antd'
import { Icon } from '@iconify/react'
import { useSelector } from 'react-redux'
import { dispatch, select } from '../../../../redux/store'
import { AntDesignFormSelect } from '../../../../sharedComponents/form-select/FormSelect'
import { LoadingOutlined } from '@ant-design/icons'
import { decryptValueWithPrivateKey } from '../../../../utils/helpers'
import { adminServices } from '../../../../services'
import _debounce from 'lodash/debounce'

const bodyStyle = {
  padding: 0
}

const DirectDebitCheckModal = ({ visible, onCancel, action, userIdFromParams }) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUsername, setSelectedUsername] = useState(null)
  const [selectedProvider, setSelectedProvider] = useState('flick')
  const existingUsers = useSelector(select.users.getAltUsersNotAdmin)
  const [bvn, setBvn] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasAtLeaseOneApprovedDebitAccount, setHasAtLeaseOneApprovedDebitAccount] = useState(false)
  const [_, setSearchText] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [usersLoaded, setUsersLoaded] = useState(false)
  const [modalKey, setModalKey] = useState(0)

  // Add a loading state for the select
  const usersLoading = useSelector(({ loading }) => loading.effects.users.getAllAltUsers)

  // Add provider options
  const providerOptions = useMemo(
    () => [
      { label: 'Flick', value: 'flick' },
      { label: 'Paystack', value: 'paystack' }
    ],
    []
  )

  const debouncedSearch = useMemo(
    () =>
      _debounce(async value => {
        setSearchLoading(true)
        if (!value) {
          await dispatch.users.getAllAltUsers({
            showAll: true
          })
          setSearchLoading(false)
          return
        }

        try {
          await dispatch.users.getAllAltUsers({
            search: value,
            page: 1
          })
        } catch (error) {
          console.error('Search error:', error)
        } finally {
          setSearchLoading(false)
        }
      }, 300),
    []
  )

  // Handle search input change
  const handleSearch = useCallback(
    value => {
      setSearchText(value)
      debouncedSearch(value)
    },
    [debouncedSearch]
  )

  // Memoize the handleUserChange function
  const handleUserChange = useCallback(
    async selectedUser => {
      setLoading(true)
      setSelectedUser(selectedUser)

      const user = existingUsers.find(user => user.id === selectedUser)
      if (user) {
        setSelectedUsername(`${user?.first_name} ${user?.last_name}`)
        if (user?.bvn) {
          const decryptedBvn = decryptValueWithPrivateKey(user?.bvn)
          setBvn(decryptedBvn)
          const response = await adminServices.userHasDirectDebit(selectedUser).catch(() => {
            setLoading(false)
            return null
          })
          setHasAtLeaseOneApprovedDebitAccount(!!response?.data?.data?.has_debit)
        } else {
          setBvn('')
        }
      }

      setLoading(false)
    },
    [existingUsers]
  )

  // Fetch user data when userIdFromParams is provided and existingUsers is available
  useEffect(() => {
    async function fetchUserData() {
      if (userIdFromParams && existingUsers && existingUsers.length > 0) {
        setLoading(true)
        setSelectedUser(userIdFromParams)

        const user = existingUsers.find(user => user.id === userIdFromParams)
        if (user) {
          setSelectedUsername(`${user?.first_name} ${user?.last_name}`)
          if (user?.bvn) {
            const decryptedBvn = decryptValueWithPrivateKey(user?.bvn)
            setBvn(decryptedBvn)
            const response = await adminServices.userHasDirectDebit(userIdFromParams).catch(() => {
              return null
            })
            setHasAtLeaseOneApprovedDebitAccount(!!response?.data?.data?.has_debit)
          } else {
            setBvn('')
          }
        }

        setLoading(false)
      }
    }

    fetchUserData()
  }, [userIdFromParams, existingUsers])

  // Reset bvn if no user is selected
  useEffect(() => {
    if (!userIdFromParams && !selectedUser) {
      setBvn('')
    }
  }, [selectedUser, userIdFromParams])

  // Memoize the handleSubmission function
  const handleSubmission = useCallback(async () => {
    if (!selectedUser || !bvn || !selectedProvider) {
      notification.error({
        message: 'Validation Error',
        description: 'All fields are required'
      })
      return
    }
    action(selectedUsername, selectedUser, bvn, '', selectedProvider)
  }, [selectedUser, bvn, selectedUsername, selectedProvider, action])

  // Memoize the options array
  const selectOptions = useMemo(() => {
    if (!Array.isArray(existingUsers)) return []

    return existingUsers
      .filter(user => user?.first_name && user?.last_name && user.id)
      .map(({ first_name: firstName, last_name: lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id
      }))
  }, [existingUsers])

  // Fetch users when modal becomes visible
  useEffect(() => {
    if (visible) {
      if (!userIdFromParams) {
        setSelectedUser(null)
        setSelectedUsername(null)
        setBvn('')
      }
      setSelectedProvider('flick')
      setLoading(true)

      // Fetch users immediately when modal opens
      dispatch.users
        .getAllAltUsers({
          showAll: true
        })
        .then(() => {
          setUsersLoaded(true)
          setLoading(false)
          setModalKey(prevKey => prevKey + 1)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setUsersLoaded(false)
    }
  }, [visible, userIdFromParams])

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={500}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4' key={modalKey}>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>Initiate Direct Debit</h2>
      </div>
      <div className='px-4 pb-8 mt-8'>
        <div className='flex flex-col gap-4 justify-center px-8'>
          <div>
            <p className='py-2 font-bold'>Select Provider</p>
            <AntDesignFormSelect
              options={providerOptions}
              value={selectedProvider}
              onChange={setSelectedProvider}
              placeholder='Select Provider'
              name='provider'
            />
          </div>
          {!userIdFromParams && (
            <div>
              <p className='py-2 font-bold'>Select customer</p>
              <AntDesignFormSelect
                options={selectOptions}
                value={selectedUser}
                onChange={handleUserChange}
                placeholder='Select User'
                name='users'
                loading={usersLoading || searchLoading}
                showSearch
                onSearch={handleSearch}
                filterOption={false}
                notFoundContent={usersLoading || searchLoading ? 'Loading...' : 'No users found'}
                allowClear
              />
            </div>
          )}

          {bvn?.trim() ? (
            <div>
              <p className='py-2 font-bold'>BVN </p>
              <input
                type='text'
                disabled
                placeholder='BVN'
                name='message_header'
                value={bvn}
                className='w-full border-b py-3 border-gray-300 px-3 focus:outline-none focus:border-blue-500 mb-2'
              />
            </div>
          ) : selectedUser ? (
            <div>
              <a
                href={`/admin-customers-details/${selectedUser}`}
                className='pl-2 opacity-80 text-red-500 inline-block hover:text-red-500 hover:opacity-100'
              >
                No BVN found. Update customer profile
              </a>
            </div>
          ) : (
            <div />
          )}

          {!loading && bvn && hasAtLeaseOneApprovedDebitAccount ? (
            <p className='text-red-500 font-bold'>
              This Customer has an existing Direct Debit record <br />
              <span
                className='underline cursor-pointer text-blue-500 pr-1'
                onClick={handleSubmission}
              >
                Click here
              </span>
              to add more
            </p>
          ) : (
            <div />
          )}

          {!hasAtLeaseOneApprovedDebitAccount ? (
            <div className='flex justify-center'>
              <button
                disabled={!bvn || loading}
                onClick={!loading && bvn ? handleSubmission : null}
                className={`bg-primary rounded-md md:px-12 py-2 text-white outline border border-gray-300 flex items-center justify-center ${
                  !bvn ? 'opacity-50' : 'opacity-100'
                }`}
              >
                {loading ? (
                  <div className='flex items-center gap-3'>
                    <LoadingOutlined spin /> Checking status ...
                  </div>
                ) : (
                  'Generate consent link'
                )}
              </button>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </Modal>
  )
}

DirectDebitCheckModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  userIdFromParams: PropTypes.string
}

// Wrap the entire component with React.memo
export default React.memo(DirectDebitCheckModal)
