/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Modal, notification } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Icon } from '@iconify/react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { CONFIRM_DISBURSE, DISBURSE_LOAN } from './constant'
import { InputField } from '../input-field'
import { Link, useParams } from 'react-router-dom'
import DisburseSuccessModal from './DisburseSuccessModal'
import { dispatch } from '../../redux/store'
import { adminServices } from '../../services'

const AdminDisburseLoansModal = ({ icon, data, disabled }) => {
  const [visible, setModal] = useState(false)
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(true)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const disburseLoading = useSelector(({ loading }) => loading.effects.loans.disburseLoan)
  const [vdfAccountInfo, setVdfAccountInfo] = useState({})
  const userIdFromParams = useParams().id

  const getVdfAccountInformation = async () => {
    try {
      const { data: responseData } = await adminServices.getCustomerVfdAccountBalance(
        userIdFromParams
      )
      if (responseData.success) {
        setVdfAccountInfo(responseData.data)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  const submitFormHandle = async values => {
    try {
      const payload = {
        approved_loan_id: data.approved_loan_id,
        ignore_loan_acceptance: checked,
        disburse_date: values.disburse_date,
        source_type: values.source_of_fund,
        vfd_loan_duration: values.vfd_loan_duration
      }

      if (!payload.vfd_loan_duration) {
        delete payload.vfd_loan_duration
      }

      const isSuccessResponse = await dispatch.loans.disburseLoan(payload)
      setModal(false)
      return isSuccessResponse
    } catch (error) {
      notification.error({
        message: 'An error occurred',
        description: error.message
      })
    }
  }

  const initialValues = {
    source_of_fund: 'VFD',
    bank_name: '',
    account_name: vdfAccountInfo?.savingsProductName || '',
    account_number: vdfAccountInfo?.accountNo || '',
    disburse_amount: data?.loan_principal_amount_fixed || '',
    disburse_date: null,
    vfd_loan_duration: ''
  }

  const validationSchema = Yup.object().shape({
    source_of_fund: Yup.string().required('Source of fund is required'),
    disburse_amount: Yup.number().required('Disburse amount is required'),
    disburse_date: Yup.date().required('Birthdate is required'),
    vfd_loan_duration: Yup.number().when('source_of_fund', {
      is: 'VFD',
      then: Yup.number()
        .required('Loan duration is required for VFD source of fund')
        .positive()
        .integer()
    })
  })

  const handleSubmit = async values => {
    const formRes = await submitFormHandle(values)
    // Update this later
    if (formRes) {
      setShowSuccessModal(true)
    }
  }

  useEffect(() => {
    function fetch() {
      getVdfAccountInformation()
    }

    fetch()
  }, [])

  return (
    <>
      <span onClick={() => (disabled ? null : setModal(true))} className='hover:text-blue-600'>
        {icon}
      </span>
      <Modal
        width='70%'
        open={visible}
        onCancel={() => setModal(false)}
        footer={false}
        title={<div className='bg-primary text-white p-2 m-0 inset-0 text-lg'>Disbursed Loan</div>}
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        className='preview-modal'
        closeIcon={
          <Icon
            icon='iconamoon:close-thin'
            className='mt-5 text-white text-4xl rounded-full bg-white cursor-pointer'
            color='#b1b1ff'
          />
        }
        destroyOnClose
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <div className='flex flex-wrap mt-2'>
                <div className='block w-full '>
                  <div className='flex flex-row justify-between'>
                    <p className='mt-2 text-black text-lg text-center font-bold'>
                      {CONFIRM_DISBURSE}
                    </p>

                    <div className='flex justify-center items-center border border-gray-500 px-4'>
                      <input
                        className='w-5 h-5'
                        onChange={e => setChecked(!checked)}
                        type='checkbox'
                        checked={checked}
                        name='ignore-loan-acceptance'
                        value={checked}
                      />
                      <p className='text-primary  mr-8 mt-3 ml-2'> Ignore loan acceptance</p>
                    </div>
                  </div>

                  {loading && <div>Please wait...</div>}
                  {/* Render optionaly depending on if an account does not exist already */}
                  {!loading && !vdfAccountInfo?.accountNo && (
                    <p className='text-red-500'>
                      No account found{' '}
                      <Link
                        to={`/admin-create-customer-account/${userIdFromParams}`}
                        className='!underline !text-red-500'
                      >
                        <span className='!text-blue-500 pr-1 underline'>Click here</span>
                      </Link>{' '}
                      to create an account
                    </p>
                  )}

                  <section className='min-w-full'>
                    <div
                      className={`grid grid-cols-1 ${
                        values?.source_of_fund === 'VFD' ? 'md:grid-cols-2' : ''
                      } items-center gap-8`}
                    >
                      <div className='flex-grow w-full mt-4'>
                        <div className='text-sm text-gray-900 font-semibold'>Source of fund</div>
                        <div className=''>
                          <Field
                            as='select'
                            name='source_of_fund'
                            placeholder='Select source of fund'
                            className='form-control bg-white w-full'
                            value={values.source_of_fund}
                          >
                            <option value=''>Select one</option>
                            <option value='VFD'>VFD</option>
                            <option value='INTERNAL'>INTERNAL</option>
                          </Field>
                          <ErrorMessage
                            name='source_of_fund'
                            component='small'
                            className='text-red-500'
                          />
                        </div>
                      </div>
                      {values?.source_of_fund === 'VFD' && (
                        <div className='flex-grow'>
                          <Field
                            as={InputField}
                            trype='text'
                            name='bank_name'
                            label={<label className='text-black text-base'>Bank Name</label>}
                            placeholder='VFD MFB'
                            className='form-control bg-white'
                            value={values.bank_name}
                            disabled
                          />
                          <ErrorMessage
                            name='bank_name'
                            component='small'
                            className='text-red-500'
                          />
                        </div>
                      )}
                    </div>
                    {values?.source_of_fund === 'VFD' && (
                      <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-8 mt-4'>
                        <div className='flex-grow'>
                          <Field
                            as={InputField}
                            trype='text'
                            name='account_name'
                            label={<label className='text-black text-base'>Account Name</label>}
                            placeholder=''
                            className='form-control bg-white'
                            value={vdfAccountInfo?.savingsProductName}
                            disabled
                          />
                          <ErrorMessage
                            name='account_name'
                            component='small'
                            className='text-red-500'
                          />
                        </div>
                        <div className='flex-grow'>
                          <Field
                            as={InputField}
                            trype='text'
                            name='account_number'
                            label={<label className='text-black text-base'>Account Number</label>}
                            placeholder=''
                            className='form-control bg-white'
                            value={vdfAccountInfo?.accountNo}
                            disabled
                          />
                          <ErrorMessage
                            name='account_number'
                            component='small'
                            className='text-red-500'
                          />
                        </div>
                      </div>
                    )}

                    <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-8 mt-4 mb-5'>
                      <div className='flex-grow'>
                        <Field
                          as={InputField}
                          trype='text'
                          name='disburse_amount'
                          label={<label className='text-black text-base'>Amount to disburse</label>}
                          placeholder=''
                          className='form-control bg-white'
                          value={values.disburse_amount}
                          disabled
                        />
                        <ErrorMessage
                          name='disburse_amount'
                          component='small'
                          className='text-red-500'
                        />
                      </div>
                      <div className='flex-grow'>
                        <label className='text-sm text-gray-900 font-semibold'>
                          Select Disbursed Date
                        </label>
                        <Field
                          type='date'
                          name='disburse_date'
                          placeholder='28/02/2023'
                          value={values.disburse_date}
                          as={InputField}
                          className='form-control bg-white'
                        />
                        <ErrorMessage
                          name='disburse_date'
                          component='small'
                          className='text-red-500'
                        />
                      </div>
                    </div>

                    {values?.source_of_fund === 'VFD' && (
                      <div className='flex-grow mt-4'>
                        <Field
                          as={InputField}
                          type='number'
                          name='loan_duration'
                          label={
                            <label className='text-black text-base'>
                              VFD Loan Duration (months)
                            </label>
                          }
                          placeholder='Enter loan duration in months'
                          className='form-control bg-white'
                        />
                        <ErrorMessage
                          name='vfd_loan_duration'
                          component='small'
                          className='text-red-500'
                        />
                      </div>
                    )}
                  </section>
                </div>
              </div>
              <div className='flex justify-center mt-8'>
                <button
                  type='submit'
                  className='text-white py-3 rounded-md w-32 focus:outline-none focus:shadow-outline bg-primary'
                >
                  {disburseLoading && (
                    <i className='fa fa-spin fa-spinner mr-2 font-bold text-lg' />
                  )}
                  {DISBURSE_LOAN}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
      {showSuccessModal && <DisburseSuccessModal loading={false} />}
    </>
  )
}

AdminDisburseLoansModal.propTypes = {
  icon: PropTypes.any,
  data: PropTypes.object,
  disabled: PropTypes.bool
}

export default AdminDisburseLoansModal
