import coreCard1Icon from './assets/coreCard1.svg'
import coreCard1Icon2 from './assets/coreCard2.svg'
import coreCard1Icon3 from './assets/coreCard3.svg'
import coreCard1Icon4 from './assets/coreCard4.svg'
import coreCard1Icon5 from './assets/coreCard5.svg'
import coreCard1Icon6 from './assets/coreCard6.svg'
import BenefitsIcon1 from './assets/BenefitsIcon1.png'
import BenefitsIcon2 from './assets/BenefirsIcon2.svg'
import BenefitsIcon3 from './assets/benefitsIcon3.png'
import BenefitsIcon4 from './assets/BenefitsIcon4.svg'
import VictoriaImg from '../../images/victoriaImg.png'
import NancyImage from '../../images/NancyImg.png'
import SegunImg from '../../images/SegunImg.png'

const HEADER_TEXT = 'Careers at Vittas'
const HEADER_PARAGRAPH =
  'Join Vittas and help power growth for a new generation of business across Africa'
const BUTTON_TEXT = 'See job openings'
const WORK_THAT_MATTERS = 'Start doing work that matters'
const WORK_THAT_MATTERS_PARAGRAPH =
  'Our philosophy is simple - hire a team of diverse, passionate people  and footer a culture that empowers you to do your best work.'
const OUR_MISSION = 'Our mission'
const OUR_MISSION_PARAGRAPH =
  'At Vittas, we empower healthcare providers to deliver exceptional patient care by ensuring their financial well-being. We provide tailored financial solutions to help doctors, nurses, and other healthcare professionals achieve their financial goals, simplifying access to capital and fostering financial health. Leveraging innovative technology, we offer efficient, personalized lending solutions.'
const HOW_WE_CREATE =
  'How we create an environment where launching and expanding your business becomes exceptionally easy.'
const HOW_WE_CREATE_PARAGRAPH =
  'In the ever-evolving landscape of African business, our fintech company is dedicated to revolutionizing the way pharmacies and hospitals thrive. We understand the challenges entrepreneurs face in the healthcare sector, and our mission is to create an environment where launching and expanding your business becomes exceptionally easy.'
const OUR_CORE_VALUES = 'Our Core Values'
const INOVATION = 'Innovation'
const INOVATION_TEXT =
  'We foster a culture of continuous innovation, encouraging our team to think creatively, challenge the status quo, and find new solutions to better serve healthcare providers.'
const COLLABORATION = 'Collaboration'
const COLABORATION_TEXT =
  'We believe that great ideas come from working together. Collaboration is at the heart of our success, and we value diverse perspectives and teamwork.'
const INTEGRITY = 'Integrity'
const INTEGRITY_TEXT =
  'Our commitment to integrity is unwavering. We uphold the highest ethical standards in all that we do, ensuring trust and transparency in our relationships with employees and clients.'
const EMPOWERMENT = 'Empowerment'
const EMPOWERMENT_TEXT =
  'We empower our employees to excel in their roles, providing opportunities for growth, development, and the autonomy to make an impact in the healthcare finance industry.'
const COMMUNITY = 'Community'
const COMMUNITY_TEXT =
  'We are more than just a company; we are a supportive and inclusive community. We encourage a sense of belonging and camaraderie among our employees.'
const TRANSPARENCY = 'Transparency'
const TRANSPARENCY_TEXT =
  "we're a transparent and inclusive community. Our commitment to openness fosters belonging and camaraderie, ensuring every team member feels informed and connected."

const BENEFITS = 'Benefits'
const BENEFITS_PARAGRAPGH =
  'Our philosophy is simple - hire a team of diverse, passionate people  and footer a culture that empowers you to do your best work.'
const HEALTH_INSURANCE = 'Health insurance'
const LIFE_INSURANCE = 'Life insurance'
const PAID_ANNUAL_LEAVE = 'Paid Annual leave'
const INTERNET_ALLOWANCE = 'Internet  Allowance'
const CAREER_DEVELOPMENT = 'Career Development'
const HEALTH_INSURANCE_TEXT =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const LIFE_INSURANCE_TEXT =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const PAID_ANNUAL_LEAVE_TEXT =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const INTERNET_ALLOWANCE_TEXT =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const CAREER_DEVELOPMENT_TEXT =
  'We provide inventory financing support which will allow you to grow other parts of your business needs.'
const OPEN_ROLES = 'Open Roles'
const JOIN_THE_TEAM = 'Join the team'
const FRONTEND_ENGINEER = 'Frontend Engineer'
const ROLE_DESCRIPTION =
  "We're seeking a dedicated individual to take ownership of the frontend engineering cycle at Vittas, ensuring the seamless development and optimization of user interfaces across our platform. This role will involve driving innovation in user experience, collaborating with cross-functional teams, and staying abreast of the latest industry trends to enhance the overall frontend architecture."
const FULL_TIME = 'Full time'
const NIGERIA = 'Nigeria'
const APPLY_NOW = 'Apply Now'
const PEOPLE_AT_VITTAS = 'People at Vittas'
const PEOPLE_AT_VITTAS_TEXT =
  'Our philosophy is simple - hire a team of diverse, passionate people  and footer a culture that empowers you to do your best work.'

export const OUR_CORE_VALUES_DATA = [
  {
    icon: coreCard1Icon,
    bgColor: 'coreCard1',
    title: INOVATION,
    content: INOVATION_TEXT
  },
  {
    icon: coreCard1Icon2,
    bgColor: 'coreCard2',
    title: COLLABORATION,
    content: COLABORATION_TEXT
  },
  {
    icon: coreCard1Icon3,
    bgColor: 'coreCard3',
    title: INTEGRITY,
    content: INTEGRITY_TEXT
  },
  {
    icon: coreCard1Icon4,
    bgColor: 'coreCard2',
    title: EMPOWERMENT,
    content: EMPOWERMENT_TEXT
  },
  {
    icon: coreCard1Icon5,
    bgColor: 'coreCard1',
    title: COMMUNITY,
    content: COMMUNITY_TEXT
  },
  {
    icon: coreCard1Icon6,
    bgColor: 'coreCard3',
    title: TRANSPARENCY,
    content: TRANSPARENCY_TEXT
  }
]
export const BENEFITS_CARD_DATA = [
  {
    icon: BenefitsIcon1,
    title: HEALTH_INSURANCE,
    content: HEALTH_INSURANCE_TEXT
  },
  {
    icon: BenefitsIcon2,
    title: LIFE_INSURANCE,
    content: LIFE_INSURANCE_TEXT
  },
  {
    icon: BenefitsIcon2,
    title: PAID_ANNUAL_LEAVE,
    content: PAID_ANNUAL_LEAVE_TEXT
  },
  {
    icon: BenefitsIcon3,
    title: INTERNET_ALLOWANCE,
    content: INTERNET_ALLOWANCE_TEXT
  },
  {
    icon: BenefitsIcon4,
    title: CAREER_DEVELOPMENT,
    content: CAREER_DEVELOPMENT_TEXT
  }
]

export const VITTAS_PEOPLE_CARD_DATA = [
  {
    paragraph:
      'Being a frontend engineer at Vittas International has been an inspiring experience. The synergy between teams and access to modern technologies have enabled me to craft intuitive and efficient user interfaces that enhance the customer experience. I take pride in contributing to a company that is revolutionizing healthcare solutions with innovation and precision. Each day brings fresh challenges and learning opportunities, and Vittas provides the support and resources to help me excel.',
    name: 'Nancy Okemmadu',
    role: 'Frontend Engineer',
    image: NancyImage
  },
  {
    paragraph:
      'Being part of the sales team at Vittas International has been a rewarding experience. The collaborative environment, combined with innovative tools and resources, has helped me refine my approach to connecting with clients. I’m proud to represent a company that offers solutions truly tailored to meet the needs of healthcare providers. Every day brings new opportunities to make an impact, and Vittas empowers me to exceed expectations',
    name: 'Victoria Kehinde',
    role: 'Sales Executive',
    image: VictoriaImg
  },
  {
    paragraph:
      'At Vittas International, I’ve experienced incredible growth as a sales professional. The company’s commitment to innovation and customer satisfaction drives our team to go above and beyond. From personalized training to being part of meaningful projects, Vittas provides everything needed to excel. I’m inspired by the impact our products have on the healthcare sector and motivated by the supportive culture that fosters success.',
    name: 'Sugun Oluwadamilare',
    role: 'Sales Manager',
    image: SegunImg
  }
]

export {
  HEADER_TEXT,
  HEADER_PARAGRAPH,
  BUTTON_TEXT,
  WORK_THAT_MATTERS,
  WORK_THAT_MATTERS_PARAGRAPH,
  OUR_MISSION,
  OUR_MISSION_PARAGRAPH,
  HOW_WE_CREATE,
  HOW_WE_CREATE_PARAGRAPH,
  OUR_CORE_VALUES,
  INOVATION,
  COLLABORATION,
  INTEGRITY,
  EMPOWERMENT,
  COMMUNITY,
  TRANSPARENCY,
  BENEFITS,
  BENEFITS_PARAGRAPGH,
  HEALTH_INSURANCE,
  LIFE_INSURANCE,
  PAID_ANNUAL_LEAVE,
  INTERNET_ALLOWANCE,
  CAREER_DEVELOPMENT,
  OPEN_ROLES,
  JOIN_THE_TEAM,
  FRONTEND_ENGINEER,
  ROLE_DESCRIPTION,
  FULL_TIME,
  NIGERIA,
  APPLY_NOW,
  PEOPLE_AT_VITTAS,
  PEOPLE_AT_VITTAS_TEXT
}
