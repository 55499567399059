import React from 'react'
import vittasMock from '../../../images/vittas-mobile-mock.png'

function GetTheAppSection() {
  const redirectToPlayStore = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.vittas&pcampaignid=web_share'
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  return (
    <div className='bg-shadeOfBlue rounded-3xl  p-8  md:py-0 md:px-16'>
      <div className='flex flex-col md:gap-8 md:flex-row items-center justify-between'>
        <div className=''>
          <p className='text-2xl md:text-4xl font-bold text-white'>
            Ready to Take Control of
            <br /> Your Healthcare Financing?
          </p>
          <p className='my-5 md:text-2xl text-white'>Get Started with Vittas</p>

          <div className='flex flex-row gap-4 mb-8 md:mb-0'>
            <div className='inline-flex bg-shadeOfWhite hover:opacity-90 cursor-pointer px-8 py-4 rounded-full'>
              <div className='flex gap-2 items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26'
                  height='26'
                  fill='none'
                  viewBox='0 0 26 26'
                >
                  <path
                    fill='#212833'
                    d='M18.508 13.91a4.84 4.84 0 0 1 2.369-4.119 4.94 4.94 0 0 0-3.914-2.06c-1.648-.102-3.295 1.03-4.12 1.03-.823 0-2.059-1.03-3.5-1.03a5.15 5.15 0 0 0-4.326 2.678c-1.957 3.296-.515 8.033 1.339 10.711.823 1.339 1.956 2.678 3.295 2.678s1.854-.824 3.502-.824 2.06.824 3.501.824c1.442 0 2.369-1.34 3.296-2.678.618-.824 1.03-1.854 1.442-2.987a4.63 4.63 0 0 1-2.884-4.222m-5.767-7.414a3.707 3.707 0 0 0 3.707-3.708 3.707 3.707 0 0 0-3.707 3.708'
                  />
                </svg>

                <p className='text-storeText'>App store</p>
              </div>
            </div>

            <div
              className='inline-flex bg-shadeOfWhite hover:opacity-90 cursor-pointer px-8 py-4 rounded-full'
              onClick={redirectToPlayStore}
            >
              <div className='flex gap-2 items-center'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26'
                  height='26'
                  fill='none'
                  viewBox='0 0 26 26'
                >
                  <path
                    fill='#212833'
                    d='M4.978 4.642a1 1 0 0 0 0 .103V21.82a1 1 0 0 0 0 .103.5.5 0 0 1 .134-.237l7.673-8.393-7.673-8.414a.5.5 0 0 1-.134-.247zm.639 17.9a.72.72 0 0 0 .443-.104l9.887-5.695-.021-.02-2.44-2.678-7.622 8.342a.5.5 0 0 1-.247.154m11.195-6.283 4.305-2.482a.566.566 0 0 0 0-.989l-4.305-2.472a.5.5 0 0 1-.124.227l-2.502 2.74 2.502 2.739a.6.6 0 0 1 .124.237m-.855-6.437L6.06 4.106a.72.72 0 0 0-.443-.082c.103.03.185.082.257.154l7.611 8.342 2.441-2.677.03-.031z'
                  />
                </svg>

                <p className='text-storeText'>Google play</p>
              </div>
            </div>
          </div>
        </div>

        <div className='hidden md:block md:-mt-16'>
          <img src={vittasMock} alt='vittas mock' />
        </div>
      </div>
    </div>
  )
}

export default GetTheAppSection
