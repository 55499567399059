import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Modal, notification, Input } from 'antd'
import { Icon } from '@iconify/react'
// import { LoadingOutlined } from '@ant-design/icons'
// import { adminServices } from '../../../../services'

const bodyStyle = {
  padding: 0
}

const PaystackDirectDebitFormModal = ({
  visible,
  onCancel,
  bvn,
  userId,
  email,
  action,
  username
}) => {
  const [, setLoading] = useState(false)
  const [amount, setAmount] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  // Memoize the handleSubmission function
  const handleSubmission = useCallback(async () => {
    if (!bvn || !amount || !phoneNumber) {
      notification.error({
        message: 'Validation Error',
        description: 'All fields are required'
      })
      return
    }

    if (Number(amount) < 1000) {
      notification.error({
        message: 'Validation Error',
        description: 'Amount cannot be less than ₦1000'
      })
      return
    }

    setLoading(true)

    try {
      // const payload = {
      //   user_id: userId,
      //   bvn,
      //   redirectUrl: `${window.location.origin}/direct-debit-consent-success`,
      //   amount,
      //   email,
      //   phone_number: phoneNumber
      // }

      // const response = await adminServices.directDebitInitialize(payload)

      // const link = response?.data?.data?.url
      // notification.success({
      //   message: 'Success',
      //   description: 'Consent link generated successfully'
      // })
      action('https://paystack.com')
    } catch (error) {
      notification.error({
        message: 'Something went wrong',
        description: error?.response?.data?.message || error?.message
      })
    } finally {
      setLoading(false)
    }
  }, [action, amount, phoneNumber, bvn, userId])

  // Reset form when modal becomes visible
  useEffect(() => {
    if (visible) {
      setAmount('')
      setPhoneNumber('')
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      className='info-modal'
      closeIcon={
        <Icon
          icon='iconamoon:close-thin'
          color='#b1b1ff'
          className='mt-4 mr-8 text-white text-4xl rounded-full bg-white'
        />
      }
      bodyStyle={bodyStyle}
      width={800}
    >
      <div className='modal-header bg-primary px-6 flex justify-between pb-4'>
        <h2 className='font-bold tracking-tight mt-4 text-white text-2xl'>Initiate Direct Debit</h2>
      </div>
      <div className='px-4 pb-8 mt-8'>
        <p className='font-bold text-primary'>
          Fill the information below to initiate direct debit for customer
        </p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 justify-center w-full mt-4'>
          <div>
            <p className='py-2 font-bold'>Customer Name</p>
            <Input value={username} disabled className='bg-gray-100' />
          </div>

          <div>
            <p className='py-2 font-bold'>Email</p>
            <Input
              value={email}
              disabled
              className='bg-gray-100'
              placeholder='Enter email address'
              type='email'
            />
          </div>

          <div>
            <p className='py-2 font-bold'>Phone Number</p>
            <Input
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder='Enter phone number'
              type='tel'
            />
          </div>

          <div>
            <p className='py-2 font-bold'>Amount</p>
            <Input
              value={amount}
              onChange={e => setAmount(e.target.value)}
              placeholder='Enter amount'
              prefix='₦'
            />
          </div>
        </div>
        <div className='flex justify-center mt-8'>
          <button
            onClick={handleSubmission}
            disabled
            className='bg-primary opacity-50 rounded-md md:px-12 py-2 text-white outline border border-gray-300 flex items-center justify-center'
          >
            {/* {loading ? <LoadingOutlined spin /> : 'Generate consent link'} */}
            Coming Soon ...
          </button>
        </div>
      </div>
    </Modal>
  )
}

PaystackDirectDebitFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  bvn: PropTypes.string,
  userId: PropTypes.string,
  action: PropTypes.func.isRequired,
  username: PropTypes.string,
  email: PropTypes.string
}

export default React.memo(PaystackDirectDebitFormModal)
