import { Dropdown, notification } from 'antd'
import React, { useState } from 'react'
import { adminServices } from '../../services'
import BvnCheckModal from '../../pages/admin-pages/applications/modal/BvnCheckModal'
import BvnCheckSuccessModal from '../../pages/admin-pages/applications/modal/BvnCheckSuccessModal'
import BvnCheckStatusModal from '../../pages/admin-pages/applications/modal/BvnCheckStatusModal'
import DirectDebitCheckModal from '../../pages/admin-pages/applications/modal/DirectDebitCheckModal'
import DirectDebitFormModal from '../../pages/admin-pages/applications/modal/DirectDebitFormModal'
import DirectDebitCheckStatusModal from '../../pages/admin-pages/applications/modal/DirectDebitCheckStatusModal'
import PaystackDirectDebitFormModal from '../../pages/admin-pages/applications/modal/PaystackDirectDebitFormModal'
import PropTypes from 'prop-types'

function CheckBvn({ userIdFromParams }) {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalStatusVisible, setModalStatusVisible] = useState(false)
  const [modalSuccessVisible, setModalSuccessVisible] = useState(false)

  // Direct Debit
  const [modalDDVisible, setModalDDVisible] = useState(false)
  const [modalDDFormVisible, setModalDDFormVisible] = useState(false)
  const [modalDDStatusVisible, setModalDDStatusVisible] = useState(false)

  const [selectedUsername, setSelectedUsername] = useState(null)
  const [userId, setUserId] = useState(null)
  const [bvn, setBvn] = useState(null)
  const [bankData, setBankData] = useState([])
  const [email, setEmail] = useState(null)

  const [link, setLink] = useState(null)
  const [provider, setProvider] = useState(null)

  const closeAllModals = () => {
    setModalVisible(false)
    setModalSuccessVisible(false)
    setModalStatusVisible(false)
  }

  const closeAllDDModals = () => {
    setModalDDVisible(false)
    setModalDDFormVisible(false)
    setModalDDStatusVisible(false)
  }

  const handleCheckBvn = async () => {
    await adminServices
      .bvnAccountLookup({
        user_id: userId,
        bvn
      })
      .then(res => {
        if (res?.data?.status === 'waiting customer consent') {
          notification.info({
            message: 'Info',
            description: res?.data?.status
          })
          return
        }

        notification.success({
          message: 'Success',
          description: 'Account lookup successfully'
        })

        closeAllModals()
        setBankData(res?.data)
        setModalSuccessVisible(true)
      })
      .catch(error => {
        notification.error({
          message: 'Something went wrong',
          description: error?.response?.data?.message || error?.message
        })
      })
  }

  const handleSilentCheckBvn = async (userId, bvn) => {
    return adminServices
      .bvnAccountLookup({
        user_id: userId,
        bvn
      })
      .then(res => {
        if (res?.data?.status === 'waiting customer consent') {
          return []
        }
        setBankData(res?.data)
        return res?.data
      })
      .catch(() => {
        return []
      })
  }

  const items = [
    {
      key: 'direct-debit',
      label: 'Direct Debit',
      onClick: () => setModalDDVisible(true)
    }
  ]

  const fullItems = [
    {
      key: 'check-bvn',
      label: 'Check BVN',
      onClick: () => setModalVisible(true)
    },
    {
      key: 'direct-debit',
      label: 'Direct Debit',
      onClick: () => setModalDDVisible(true)
    }
  ]

  return (
    <div>
      <Dropdown menu={{ items: userIdFromParams ? items : fullItems }} placement='bottom'>
        <button className='bg-primary px-4 py-2 text-white rounded-md flex items-center gap-2'>
          Actions
          <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
              d='M14.9396 6.71289L10.0496 11.6029C9.47207 12.1804 8.52707 12.1804 7.94957 11.6029L3.05957 6.71289'
              stroke='white'
              strokeWidth='1.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </button>
      </Dropdown>

      {/* BVN */}
      <BvnCheckModal
        visible={modalVisible}
        onCancel={closeAllModals}
        handleCheckBvn={handleSilentCheckBvn}
        action={(username, userId, bvn, consentLink, email) => {
          closeAllModals()
          setModalStatusVisible(true)
          setSelectedUsername(username)
          setLink(consentLink)
          setUserId(userId)
          setBvn(bvn)
          setEmail(email)
        }}
        viewBankAction={(username, userId, bvn) => {
          closeAllModals()
          setModalSuccessVisible(true)
          setSelectedUsername(username)
          setUserId(userId)
          setBvn(bvn)
        }}
      />

      <BvnCheckStatusModal
        visible={modalStatusVisible}
        onCancel={closeAllModals}
        handleCheckBvn={handleCheckBvn}
        propData={{
          userId,
          link,
          username: selectedUsername
        }}
      />

      <BvnCheckSuccessModal
        visible={modalSuccessVisible}
        onCancel={closeAllModals}
        selectedUsername={selectedUsername}
        bankData={bankData}
      />

      {/* Direct Debit */}
      <DirectDebitCheckModal
        visible={modalDDVisible}
        onCancel={closeAllDDModals}
        userIdFromParams={userIdFromParams}
        action={(username, userId, bvn, consentLink, selectedProvider) => {
          closeAllDDModals()
          setModalDDFormVisible(true)
          setSelectedUsername(username)
          setLink(consentLink)
          setUserId(userId)
          setBvn(bvn)
          setProvider(selectedProvider)
        }}
      />

      {/* Conditionally render the appropriate form modal */}
      {provider === 'paystack' ? (
        <PaystackDirectDebitFormModal
          visible={modalDDFormVisible}
          onCancel={closeAllDDModals}
          bvn={bvn}
          userId={userId}
          email={email}
          username={selectedUsername}
          action={consentLink => {
            closeAllDDModals()
            setModalDDStatusVisible(true)
            setLink(consentLink)
          }}
        />
      ) : (
        <DirectDebitFormModal
          visible={modalDDFormVisible}
          onCancel={closeAllDDModals}
          bvn={bvn}
          userId={userId}
          action={consentLink => {
            closeAllDDModals()
            setModalDDStatusVisible(true)
            setLink(consentLink)
          }}
        />
      )}

      <DirectDebitCheckStatusModal
        visible={modalDDStatusVisible}
        onCancel={closeAllDDModals}
        propData={{
          userId,
          link,
          username: selectedUsername
        }}
      />
    </div>
  )
}

CheckBvn.propTypes = {
  userIdFromParams: PropTypes.string
}

export default CheckBvn
